<template>
    <v-card flat class="pa-0">
        <validation-observer ref="observer">
            <validation-provider
                name="Name"
                rules="required"
                v-slot="{ errors }"
            >
                <v-text-field
                    v-model="fullName"
                    label="Name"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                ></v-text-field>
            </validation-provider>

            <validation-provider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
            >
                <v-text-field
                    v-model="email"
                    label="Email"
                    :error-messages="errors"
                    required
                    outlined
                    dense
                ></v-text-field>
            </validation-provider>

            <VuePhoneNumberInput
                :value="mobileNum"
                @update="onMobileNumUpdate"
                :preferred-countries="preferredCountries"
                :default-country-code="defaultCountry"
                clearable
                class="pb-6"
            />
        </validation-observer>

        <v-alert type="success" v-if="success" text dense outlined>
            Thank you for your interest in {{ studio.studioName }}. We will get
            back to you soon.
        </v-alert>
        <v-alert type="error" text dense outlined v-if="error">
            {{ error }}
        </v-alert>

        <v-row no-gutters justify="center" class="pb-3">
            <v-btn @click="submit" :loading="loading" color="primary" large>
                Submit
            </v-btn>
        </v-row>
    </v-card>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import parsePhoneNumber from 'libphonenumber-js'
import { submitWebsiteForm } from '@/util/cloudFunctions/websiteForm'

export default {
    components: {
        VuePhoneNumberInput,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    mounted() {
        this.initMobileNum()
    },
    data() {
        return {
            error: '',
            loading: false,
            success: false,

            // Form details
            fullName: '',
            email: '',

            // mobileNum field
            defaultCountry: '',
            preferredCountries: [],
            isMobileNumValid: false,

            country: '', // ISO2 Code "SG", "MY", etc
            mobileNum: '', // 8886 8822 (national number formatted by vue-phone-number-input)
        }
    },
    methods: {
        async submit() {
            this.error = ''
            const isValid = await this.validate()
            if (!isValid) {
                return
            }
            let formattedMobileNum
            if (this.mobileNum) {
                formattedMobileNum = this.buildMobileNum()
            } else {
                formattedMobileNum = ''
            }

            this.loading = true

            const result = await submitWebsiteForm(
                this.studio.id,
                this.fullName,
                this.email,
                formattedMobileNum
            )
            if (!result.success) {
                this.onError(result.error)
                return
            }
            this.onSuccess()
        },
        onError(error) {
            this.error = error
            this.loading = false
        },
        onSuccess() {
            this.error = ''
            this.loading = false
            this.success = true
        },
        buildMobileNum() {
            return parsePhoneNumber(this.mobileNum, this.country).number
        },
        async validate() {
            const isValid = await this.$refs.observer.validate()
            const isMobileNumValid = this.validateMobileNum()
            return isValid && isMobileNumValid
        },
        validateMobileNum() {
            if (!this.isMobileNumValid) {
                this.error = 'Mobile number is invalid'
            }
            return this.isMobileNumValid
        },
        onMobileNumUpdate(payload) {
            this.isMobileNumValid = payload.isValid
            this.country = payload.countryCode
            this.mobileNum = payload.phoneNumber
        },
        initMobileNum() {
            this.preferredCountries = [this.studio.country]
            this.defaultCountry = this.studio.country
            this.country = this.studio.country
        },
    },
}
</script>

<template>
    <v-div v-if="content || imageUrl" class="ma-2">
        <v-card flat v-if="content">
            <v-banner outlined>
                {{ content }}
            </v-banner>
        </v-card>
        <v-img
            v-else-if="imageUrl"
            :src="imageUrl"
            max-height="200px"
            contain
        ></v-img>
    </v-div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STUDIO_MODULE_NAME } from '@/store/moduleNames'
import { getStudioBanner } from '@/util/announcements/banner'

export default {
    data() {
        return {
            content: null,
            imageUrl: null,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    async created() {
        const banner = await getStudioBanner(this.studio.id)
        if (banner) {
            this.content = banner.content
            this.imageUrl = banner.imageUrl
        }
    },
}
</script>

import { functions, httpsCallable } from '@/firebase'

async function submitWebsiteForm(studioId, fullName, email, mobileNum) {
    const submitWebsiteFormFn = httpsCallable(functions, 'submitWebsiteForm')
    return submitWebsiteFormFn({
        studioId,
        fullName,
        email,
        mobileNum,
    })
        .then((result) => result.data)
        .catch((error) => ({ success: false, error }))
}

export { submitWebsiteForm }

<template>
    <v-alert dense :type="type">
        <v-row align="center">
            <v-col class="grow">
                {{ message }}
            </v-col>
            <v-col class="shrink">
                <v-icon color="black" @click="clearAlertState"
                    >mdi-close</v-icon
                >
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
import { mapMutations } from 'vuex'
import { ALERT_MODULE_NAME } from '@/store/moduleNames'

export default {
    props: ['type', 'message'],
    methods: {
        ...mapMutations({
            clearAlertState: `${ALERT_MODULE_NAME}/clearAlertState`,
        }),
    },
}
</script>
<style scoped>
.v-alert {
    position: fixed;
    min-width: 300px;
    left: 50%;
    top: 100px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    z-index: 9999;
}
</style>

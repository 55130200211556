import { db } from '@/firebase'
import {
    collection,
    limit,
    where,
    orderBy,
    query,
    getDocs,
} from 'firebase/firestore'

export async function getStudioBanner(studioId) {
    const q = query(
        collection(db, 'banners'),
        where('studioId', '==', studioId),
        where('isActive', '==', true),
        orderBy('updatedAt', 'desc'),
        limit(1)
    )
    const querySnapshot = await getDocs(q)
    const banners = querySnapshot.docs
    if (querySnapshot.docs.length === 0) {
        return null
    }
    return { ...banners[0].data(), id: banners[0].id }
}
